import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import SidebarNav from "../header/SidebarNav";
import MainDropdownButton from "../header/MainDropdownButton";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import PlaceIcon from "@mui/icons-material/Place";
// import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
// import EmailIcon from "@mui/icons-material/Email";
// import BlockMap from "../industry/blocks/BlockMap";
// import FoodBankIcon from "@mui/icons-material/FoodBank";
// import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import ApartmentIcon from "@mui/icons-material/Apartment";
// import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import FAQS from "react-faqs-component";
import CountUp from "react-countup";
import { Element } from "react-scroll";

const FadeInSection = ({ children, sectionId }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const yOffset = window.pageYOffset;
        const element = document.getElementById(sectionId);
        const rect = element.getBoundingClientRect();

        if (yOffset + window.innerHeight >= rect.top + yOffset) {
            setIsVisible(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`fade-in-section ${isVisible ? "is-visible" : ""}`} id={sectionId}>
            {children}
        </div>
    );
};

function Mainhome3() {
    const [isVisible, setIsVisible] = useState(false);
    // const [isVisible1, setIsVisible1] = useState(false);

    // const handleScroll1 = () => {
    //     const yOffset = window.pageYOffset;
    //     const element = document.getElementById(sectionId);
    //     const rect = element.getBoundingClientRect();

    //     if (yOffset + window.innerHeight >= rect.top + yOffset) {
    //       setIsVisible1(true);
    //     }
    //   };

    //   useEffect(() => {
    //     window.addEventListener('scroll', handleScroll1);
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll1);
    //     };
    //   }, []);

    //   const handleScroll1 = () => {
    //     const yOffset = window.pageYOffset;
    //     const element = document.getElementById('fade-in-section'); // Change this to the ID of your section
    //     const rect = element.getBoundingClientRect();

    //     if (yOffset + window.innerHeight >= rect.top + yOffset) {
    //       setIsVisible1(true);
    //     }
    //   };

    //   useEffect(() => {
    //     window.addEventListener('scroll', handleScroll1);
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll1);
    //     };
    //   }, []);

    const handleScroll = () => {
        const yOffset = window.pageYOffset;
        const triggerOffset = 100; // Adjust this value as needed

        if (yOffset > triggerOffset) {
            setIsVisible(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        // Trigger the zoom effect when the component mounts
        const section = document.getElementById("zoom-in-section");
        section.classList.add("zoom-in-section");

        // Clean up the animation class after it's finished
        const onAnimationEnd = () => {
            section.classList.remove("zoom-in-section");
            section.removeEventListener("animationend", onAnimationEnd);
        };

        section.addEventListener("animationend", onAnimationEnd);

        return () => {
            section.removeEventListener("animationend", onAnimationEnd);
        };
    }, []);

    const settings12 = {
        dots: false,
        infinite: true,
        speed: 750,
        slidesToShow: 1,
        slidesToScroll: 1,
        duration: 30,
        autoplay: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };

    const data = {
        faqs: [
            {
                question: "Can I use Hogist’s online services for free?",
                answer: "Hogist’s services are free. We will never charge you beyond the pricing listed on the website for the service opted by you. Our online catering service is free of cost and after every successful transaction, you will be receiving the complete breakup of charges incurred. There are no hidden charges.",
            },

            {
                question: "What kind of food can I get?",
                answer: "You name it and we have it. We have collaborated with a wide range of restaurants, caterers, renowned chefs, Homecooks. All you have to do is get in touch with them, choose the product, set your quantity and make the payment. If you have a caterer in mind and you don’t find them on Hogist, please feel free to drop their details at support@hogist.com. and we will get in touch with them.",
            },

            {
                question: "Do you have home delivery option or I will have to pick it up?",
                answer: "All the orders will be delivered to you at your doorstep.Anyways, if you wish to pick the order by yourself, then you can set your order as a takeaway and pick it up from the Vendor’s place.",
            },

            {
                question: "Can I expect 100% satisfaction from Hogist?",
                answer: "Definitely with Top Class Customer Service! You can contact us anytime if you feel our services are not as expected.We will make sure that we deliver our services with 100% customer satisfaction. Also, if you find it appropriate, we can even serve your guests on your behalf.",
            },

            {
                question: "Do you follow a secure process for accepting orders?",
                answer: "Yes. We use 128-bit SSL encryption method to capture your data. The information you provide us is stored using secured mediums so that there is no chance of data theft. Refer our Privacy Policy to get more information on how we store and use your information.",
            },
        ],
    };

    return (
        <div>
            <div className="mi-main-style">
                {/* Nav start */}
                <div className="mobile-view-m3">
                    <div className="m3-mainhome-navbar-style">
                        <div className="m3navbar-container">
                            <div className="row itemcenter">
                                <div className="col-lg-2">
                                    <Link to="/">
                                        <img src="images/flogo.png" alt="" style={{ width: "60%" }} />
                                    </Link>
                                </div>
                                <div className="col-lg-7">
                                    <ul className="m3navbar-menu ">
                                        <li>
                                            {" "}
                                            <Link to="/">Home </Link>
                                        </li>
                                        <li>
                                            <a href="#review">Reviews </a>
                                        </li>
                                        <li>
                                            <a href="#features">Features</a>
                                        </li>
                                        <li>
                                            <Link to="/hogist-menu-bank/">Menu Bank</Link>
                                        </li>
                                        <li>
                                            <Link to="/industrial-catering-services-near-me/">Industrials</Link>
                                        </li>
                                        <li>
                                            <Link to="/corporate-catering-services-in-chennai/">Corporate</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 d-flex">
                                    {/* <SidebarNav /> */}
                                    <MainDropdownButton />
                                    <a href="#app">
                                        {" "}
                                        <button className="m3-rg-btn-nav-style" style={{ marginLeft: "7%" }}>
                                            {" "}
                                            GET STARTED
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Nav end */}
                {/* Home start */}
                <div className="padding-section-large">
                    {/* <FadeInSection sectionId="section11"> */}
                    <div className="m3-home-header-component" id="zoom-in-section">
                        {/* <div className={`m3fade-in-left ${isVisible ? "visible" : ""}`}> */}

                        <div className="m3-home-header-content m3-anime-mainhome ">
                            <h1 className="h1-m3slider-text">
                                {" "}
                                Effortlessly <span style={{ color: "#c60800" }}>H</span>andle and Enhance your every
                                Event with HOGIST.
                            </h1>
                            <p className="m3-spacer-small">
                                {" "}
                                Experience the all new corporate and industrial catering that supports your business
                                goal and is very true to your company's character.
                            </p>
                            <div className="m3-spacer-large">
                                <div className="m3-button-group">
                                    <a href="#app">
                                        {" "}
                                        <button className="m3-mainscreen-btn-style">GET STARTED</button>
                                    </a>
                                    <a
                                        style={{ color: "#fff" }}
                                        href="https://api.whatsapp.com/send?phone=+919962667733&text=Welcome HOGIST - The Catering service in Chennai. "
                                    >
                                        {" "}
                                        <button className="m3-mainscreen-btn-style1"> TALK TO US</button>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* </div> */}
                        <div className="m3-mainscreen-img">
                            {/* <div className={`m3fade-in-right ${isVisible ? "visible" : ""}`}> */}
                            <img src="images/tr.png" alt="bulk food box" className="home-header-image" />
                            {/* </div> */}
                        </div>
                    </div>
                    {/* </FadeInSection> */}
                </div>
                {/* Home End */}

                {/* Brand slid start */}
                <div>
                    <div
                        style={{
                            paddingBottom: "4%",
                        }}
                        className="mobile-br"
                    >
                        <div
                            className="container mt-4 "
                            style={{
                                border: "2px dotted #c60800",
                                borderRadius: "2.5rem",
                                padding: "4%",
                            }}
                        >
                            <div className="row">
                                <div className="col-lg-6 itemcenter">
                                    <h2>Trusted by the world's best companies. Join the ranks of the elite.</h2>
                                </div>
                                <div className="col-lg-6">
                                    <div className="m3slider-container ">
                                        <div className="row">
                                            <div className="col-lg-6 ">
                                                <marquee direction="up" className="client-m3-height">
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/i-client1.jpg" alt="Slide 1" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/i-client2.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/i-client3.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/i-client4.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/i-client5.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/i-client6.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/i-client7.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/i-client8.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/i-client9.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/i-client10.jpg" alt="Slide 2" />
                                                    </div>
                                                </marquee>
                                            </div>
                                            <div className="col-lg-6">
                                                <marquee direction="down" className="client-m3-height">
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/c-client1.jpg" alt="Slide 1" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/c-client2.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/c-client3.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/c-client4.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/c-client5.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/c-client6.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/c-client7.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/c-client8.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/c-client9.jpg" alt="Slide 2" />
                                                    </div>
                                                    <div className="m3-clinte-img-style">
                                                        <img src="images/client/c-client10.jpg" alt="Slide 2" />
                                                    </div>
                                                </marquee>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Brand slid end */}

                {/* Our Futurs start */}
                <FadeInSection sectionId="section1">
                    <div className="padding-page-m3 " id="features">
                        <p className="m3-spacer-small m3-font-600">
                            <span style={{ color: "#c60800", fontWeight: "600", fontSize: "18px" }}>O</span>ur Feature
                        </p>
                        <div className="m3scroll-animation-top">
                            <div className="container-fluid ">
                                <div className="row ">
                                    <div className="col-lg-6">
                                        <h2 className="">Effortlessly oversee and enhance your daily catering.</h2>
                                    </div>
                                    <div className="col-lg-6">
                                        <p>
                                            Now stop worrying about Delivery delays, food quantity & quality, hygiene,
                                            and even staff shortage. Get all your Unsolved food & beverages problem
                                            solved with{" "}
                                            <span style={{ color: "#c60800", fontWeight: "600", fontSize: "1rem" }}>
                                                HOGIST.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="row m3-card-wdith">
                                    <div className="col-lg-4 mb-4">
                                        <div className="card card-bg-style p-4 ">
                                            <div className="sv-card-images">
                                                <img
                                                    src="images/indu2.png"
                                                    alt="Industrial Catering"
                                                    style={{ width: "100%", height: "100%" }}
                                                    className="card-blog-top-style-all-img"
                                                />
                                            </div>
                                            <div>
                                                <h4 className="mt-3 mb-2"> Over 100 top caterers </h4>
                                                <p>
                                                    Community of 100+ best Vendors all over the city for flawless
                                                    Operations with flexibility, Consistency, and safe food.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <div className="card card-bg-style  p-4">
                                            <div className="sv-card-images">
                                                <img
                                                    src="images/corpo2.png"
                                                    alt="Corporate Catering"
                                                    style={{ width: "100%", height: "100%" }}
                                                    className="card-blog-top-style-all-img"
                                                />
                                            </div>
                                            <div>
                                                <h4 className="mt-3 mb-2"> No Menu Markups</h4>
                                                <p>
                                                    Say Goodbye to Menu Markups and have a transparent dining
                                                    experience. You'll always know that your satisfaction is our top
                                                    priority.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <div className="card card-bg-style  p-4">
                                            <div className="sv-card-images">
                                                <img
                                                    src="images/out1.png"
                                                    alt="Out Door Catering"
                                                    style={{ width: "100%", height: "100%" }}
                                                    className="card-blog-top-style-all-img"
                                                />
                                            </div>
                                            <div>
                                                <h4 className="mt-3 mb-2"> Any Group size, or Budget</h4>
                                                <p>
                                                    We're dedicated to understanding your desires, preferences, and
                                                    budgetary constraints. Tailored Experiences, Every Time
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <div className="card card-bg-style  p-4">
                                            <div className="sv-card-images">
                                                <img
                                                    src="images/coffe1.png"
                                                    alt="Cafeteria Catering"
                                                    style={{ width: "100%", height: "100%" }}
                                                    className="card-blog-top-style-all-img"
                                                />
                                            </div>
                                            <div>
                                                <h4 className="mt-3 mb-2"> Delivering on time</h4>
                                                <p>
                                                    Professionally prepared and delivering food you can rely on for your
                                                    meetings and events with real-time delivery vehicle tracking.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-4">
                                        <div className="card card-bg-style  p-4">
                                            <div className="sv-card-images">
                                                <img
                                                    src="images/familyev1.png"
                                                    alt="Cafeteria Catering"
                                                    style={{ width: "100%", height: "100%" }}
                                                    className="card-blog-top-style-all-img"
                                                />
                                            </div>
                                            <div>
                                                <h4 className="mt-3 mb-2"> 24/7 support from Experts</h4>
                                                <p>
                                                    Expert support available around the clock for all your needs. We're
                                                    here whenever you need us. Call us at +91 99626 67733.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 mb-4">
                                        <div className="card card-bg-style  p-4">
                                            <div className="sv-card-images">
                                                <img
                                                    src="images/instit1.png"
                                                    alt="Cafeteria Catering"
                                                    style={{ width: "100%", height: "100%" }}
                                                    className="card-blog-top-style-all-img"
                                                />
                                            </div>
                                            <div>
                                                <h4 className="mt-3 mb-2">More options</h4>
                                                <p>
                                                    Food for hybrid offices, daily employee meals, and fluctuating
                                                    headcounts. Including previous day cancellation and menu changes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row m3-card-wdith">
                                <div className="col-lg-4 mb-4">
                                    <div className="card card-bg-style p-4 ">
                                        <div className="sv-card-images">
                                            <img
                                                src="images/indu1.png"
                                                alt="Industrial Catering"
                                                style={{ width: "100%", height: "100%" }}
                                                className="card-blog-top-style-all-img"
                                            />
                                        </div>
                                        <div>
                                            <h4 className="mt-3 mb-2"> Industrial Catering</h4>
                                            <p>
                                                Hogist is the one-stop solution for all your industrial food needs. Get
                                                the best catering services through us. We offer best quality with
                                                different taste in our catering services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <div className="card card-bg-style  p-4">
                                        <div className="sv-card-images">
                                            <img
                                                src="images/corpo1.png"
                                                alt="Corporate Catering"
                                                style={{ width: "100%", height: "100%" }}
                                                className="card-blog-top-style-all-img"
                                            />
                                        </div>
                                        <div>
                                            <h4 className="mt-3 mb-2"> Corporate Catering</h4>
                                            <p>
                                                Hogist is the one-stop solution for all your corporate food needs. Get
                                                the best catering services through us. We offer best quality with
                                                different taste in our catering services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <div className="card card-bg-style  p-4">
                                        <div className="sv-card-images">
                                            <img
                                                src="images/out1.png"
                                                alt="Out Door Catering"
                                                style={{ width: "100%", height: "100%" }}
                                                className="card-blog-top-style-all-img"
                                            />
                                        </div>
                                        <div>
                                            <h4 className="mt-3 mb-2"> Out Door Catering</h4>
                                            <p>
                                                Hogist is the one-stop solution for all your event food needs. Get the
                                                best catering services through us. We offer best quality with different
                                                taste in our catering services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <div className="card card-bg-style  p-4">
                                        <div className="sv-card-images">
                                            <img
                                                src="images/coffe1.png"
                                                alt="Cafeteria Catering"
                                                style={{ width: "100%", height: "100%" }}
                                                className="card-blog-top-style-all-img"
                                            />
                                        </div>
                                        <div>
                                            <h4 className="mt-3 mb-2"> Cafeteria Catering</h4>
                                            <p>
                                                Hogist is the one-stop solution for all your cafeteria needs. Get the
                                                best catering services through us. We offer best quality with different
                                                taste in our catering services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 mb-4">
                                    <div className="card card-bg-style  p-4">
                                        <div className="sv-card-images">
                                            <img
                                                src="images/familyev1.png"
                                                alt="Cafeteria Catering"
                                                style={{ width: "100%", height: "100%" }}
                                                className="card-blog-top-style-all-img"
                                            />
                                        </div>
                                        <div>
                                            <h4 className="mt-3 mb-2"> Family Events</h4>
                                            <p>
                                                Hogist is the one-stop solution for all your cafeteria needs. Get the
                                                best catering services through us. We offer best quality with different
                                                taste in our catering services.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 mb-4">
                                    <div className="card card-bg-style  p-4">
                                        <div className="sv-card-images">
                                            <img
                                                src="images/instit1.png"
                                                alt="Cafeteria Catering"
                                                style={{ width: "100%", height: "100%" }}
                                                className="card-blog-top-style-all-img"
                                            />
                                        </div>
                                        <div>
                                            <h4 className="mt-3 mb-2"> Institutional Catering</h4>
                                            <p>
                                                Hogist is the one-stop solution for all your cafeteria needs. Get the
                                                best catering services through us. We offer best quality with different
                                                taste in our catering services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </FadeInSection>
                {/* Our Futurs start */}

                {/* main start */}
                <FadeInSection sectionId="section2">
                    <div className="container-fluid padding-ab-m3 txt-align-center pb-4">
                        <div style={{ color: "#c60800" }} className="m3-font-600">
                            HOGIST
                        </div>
                        <h2 className="m3-main-text-pad-style">
                            Can serve any Company and Events, No matter the Size, Budget, or Complexsity of Operation
                        </h2>
                        <p className="m3-main-text-pad-style m3-font-style-n">
                            Powered with <span style={{ color: "#c60800", fontWeight: "800" }}>Technology</span> to
                            completely <span style={{ color: "#c60800", fontWeight: "800" }}>Zero</span> the on-site
                            operational <span style={{ color: "#c60800", fontWeight: "800" }}>Errors.</span>
                        </p>{" "}
                        <a
                            style={{ color: "#fff" }}
                            href="https://api.whatsapp.com/send?phone=+919962667733&text=Welcome HOGIST - The Catering service in Chennai. "
                        >
                            {" "}
                            <button className="cnt-btn-style-main">ORDER NOW</button>{" "}
                        </a>
                        <div className="mt-4">
                            {" "}
                            <Slider {...settings12}>
                                <img src="images/banners/s1.png" alt="main images" className="m3-main-img" />
                                <img src="images/banners/s2.png" alt="main images" className="m3-main-img" />
                                <img src="images/banners/s3.png" alt="main images" className="m3-main-img" />
                                <img src="images/banners/s4.png" alt="main images" className="m3-main-img" />
                                <img src="images/banners/s5.png" alt="main images" className="m3-main-img" />
                                <img src="images/banners/s6.png" alt="main images" className="m3-main-img" />
                                <img src="images/banners/s7.png" alt="main images" className="m3-main-img" />
                                <img src="images/banners/s8.png" alt="main images" className="m3-main-img" />
                                <img src="images/banners/s9.png" alt="main images" className="m3-main-img" />
                            </Slider>
                        </div>
                    </div>
                </FadeInSection>

                {/* main end */}

                {/* Monitor Services Start */}
                <FadeInSection sectionId="section3">
                    <div className="container-fluid padding-ab-m3 mt-4 ">
                        <div className="row">
                            <div className="col-lg-7 " style={{ color: "#fff" }}>
                                <div className="m3-futures-style">
                                    <div style={{ marginTop: "5%" }}>
                                        <h2>
                                            Monitor and Track your Corporate & Industrial Bulk Food{" "}
                                            <span style={{ color: "#c60800", fontWeight: "800", fontSize: "34px" }}>
                                                O
                                            </span>
                                            rder from Anywhere{" "}
                                        </h2>
                                    </div>

                                    <p className="mt-3" style={{ textAlign: "justify" }}>
                                        Now stop worrying about Delivery delays, food quantity & quality, hygiene, and
                                        even staff shortage.
                                    </p>
                                    <ul style={{ paddingLeft: "0px" }}>
                                        <li style={{ padding: "1% 0%" }} className="para-fontsize-m3">
                                            <CheckCircleOutlineIcon style={{ color: "#c60800", marginRight: "1%" }} />
                                            Flexible, Contact-free food solution
                                        </li>
                                        <li style={{ padding: "1% 0%" }} className="para-fontsize-m3">
                                            <CheckCircleOutlineIcon style={{ color: "#c60800", marginRight: "1%" }} />
                                            Timely Delivery as committed
                                        </li>
                                        <li style={{ padding: "1% 0%" }} className="para-fontsize-m3">
                                            <CheckCircleOutlineIcon style={{ color: "#c60800", marginRight: "1%" }} />
                                            Excellent Hospitality/Onsite Service
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-5 ">
                                <div className="main-about-img-style-moniter">
                                    {" "}
                                    <img src="images/ab-img2.png" alt="" style={{ width: "100%", height: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </FadeInSection>
                {/* Monitor Services End */}

                {/* About Start */}
                <FadeInSection sectionId="section4">
                    <div
                        style={{
                            backgroundImage: "linear-gradient(49deg, rgb(50, 50, 50), rgb(0, 0, 0))",
                        }}
                    >
                        <div className="container-fluid padding-ab-m3">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="main-about-img-style">
                                        {" "}
                                        <img
                                            src="images/mobile.png"
                                            alt=""
                                            className="rotate "
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-7 " style={{ color: "#fff" }}>
                                    <div style={{ marginTop: "15%" }}>
                                        <div style={{ marginTop: "5%" }}>
                                            <h2 className="ab-service">
                                                An End-to-End Solution for Corporate Catering on-site operation and
                                                technology{" "}
                                            </h2>
                                        </div>

                                        <p className="mt-3" style={{ textAlign: "justify" }}>
                                            Hogist is here to take care of all these, providing motivated hospitality
                                            professionals who are committed to the success of your business as you.
                                        </p>
                                        <ul style={{ paddingLeft: "0px" }}>
                                            <li style={{ padding: "1% 0%" }} className="para-fontsize-m3">
                                                <CheckCircleOutlineIcon
                                                    style={{ color: "#c60800", marginRight: "1%" }}
                                                />
                                                loaded with the options you love
                                            </li>
                                            <li style={{ padding: "1% 0%" }} className="para-fontsize-m3">
                                                <CheckCircleOutlineIcon
                                                    style={{ color: "#c60800", marginRight: "1%" }}
                                                />
                                                Customizable Packages
                                            </li>
                                            <li style={{ padding: "1% 0%" }} className="para-fontsize-m3">
                                                <CheckCircleOutlineIcon
                                                    style={{ color: "#c60800", marginRight: "1%" }}
                                                />
                                                Menu Options that fit everyone's taste.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FadeInSection>

                {/* About End */}
                {/* Testimoniel Start */}

                <div
                    style={{
                        backgroundImage: "linear-gradient(227deg, rgb(50, 50, 50), rgb(0, 0, 0))",
                    }}
                >
                    <FadeInSection sectionId="section5">
                        <div style={{ padding: "5% 5%" }}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6 itemcenter">
                                        <div>
                                            <p className="m3-spacer-small m3-font-600">Milestones Completed</p>
                                            <h2>
                                                Best Corporate & Industrial Catering Services in Chennai & Coimbatore.
                                            </h2>
                                            <p className="mt-3">
                                                Hogist - Delivering Flexibility & Consistency along with tasty food.
                                            </p>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <h2 className="m3-counter-h2">
                                                        <CountUp end={10000} duration={40} enableScrollSpy="true" /> +
                                                    </h2>
                                                    <p>Food served Everyday</p>
                                                </div>
                                                <div className="col-lg-6">
                                                    <h2 className="m3-counter-h2">
                                                        <CountUp end={50} duration={40} enableScrollSpy="true" /> +
                                                    </h2>
                                                    <p>Corporates & Industrials clients</p>
                                                </div>
                                                <div className="col-lg-6">
                                                    <h2 className="m3-counter-h2">
                                                        <CountUp end={2} />{" "}
                                                    </h2>
                                                    <p>Times awarded the best food partner</p>
                                                </div>
                                                <div className="col-lg-6">
                                                    <h2 className="m3-counter-h2">
                                                        <CountUp end={2} />{" "}
                                                    </h2>
                                                    <p>ISO Standards Obtained in the year of 2022</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="m3-main-miles-img-style ">
                                            {" "}
                                            <img
                                                src="images/mo3.png"
                                                alt=""
                                                style={{ width: "100%", height: "100%" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeInSection>
                    <FadeInSection sectionId="section6">
                        <div style={{ padding: "5% 5%" }} id="review">
                            <div className="container-fluid mb-4">
                                <div className="row">
                                    <div className="col-lg-6 itemcenter">
                                        <div>
                                            <h2>Our Clients are never wrong</h2>
                                            <p>
                                                Our clients say it best. Here’s a small sample of the feedback we get
                                                every day.
                                            </p>
                                            <a href="tel:+91 9962374733">
                                                {" "}
                                                <button className="cnt-btn-style-main">REACH US NOW</button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card card-bg-style padding-testimonial-m3">
                                            <Slider {...settings12}>
                                                <div>
                                                    <h4>Feedback</h4>
                                                    <p>
                                                        The food is excellent and service is also super taste and today
                                                        bread halwa is very very tasty. All variety rice , raasam, kara
                                                        Kulzambu and vegetables are good taste and serve in hot
                                                        conditions. Butter milk is good really. Over all I love the food
                                                        taste, presentation, service and conditions all excellent to the
                                                        core.
                                                    </p>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img
                                                            src="images/avatars/avatar-4.png"
                                                            alt="user"
                                                            style={{ width: "8%" }}
                                                        />
                                                        <p className="pd-clint-name">Srinivasa Sampathkumar</p>
                                                        {/* <p>(Software devloper)</p> */}
                                                    </div>
                                                    <img src="images/5s.png" alt="star" style={{ width: "20%" }} />
                                                </div>
                                                <div>
                                                    <h4>Feedback</h4>
                                                    <p>
                                                        For the first time we came across someone from online who were
                                                        very professional from taking the food order,specifying the
                                                        tariff and dispatching the food delivery on time. Food was very
                                                        tasty and maintained good standards. Pricing was reasonably good
                                                        and best service. Thanks to Hogist team for Organizing dinner
                                                        menu for our Anniversary party for 50 members. I give 5 star
                                                        ratings..
                                                    </p>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img
                                                            src="images/avatars/avatar-4.png"
                                                            alt="user"
                                                            style={{ width: "8%" }}
                                                        />
                                                        <p className="pd-clint-name">Brinda Ghanesh</p>
                                                    </div>
                                                    <img src="images/5s.png" alt="star" style={{ width: "20%" }} />
                                                </div>
                                                <div>
                                                    <h4>Feedback</h4>
                                                    <p>
                                                        Recently we had a small bday party at home for just 20 people
                                                        and we're struggling to get the catering done for the same. Then
                                                        we came across Hogist and then got in touch with them. They were
                                                        blessing in disguise. They provided amazing food and the service
                                                        was also great. The delivered the food on time and all our
                                                        guests loved the food. The food was delicious and yummy. Thank
                                                        you so much.
                                                    </p>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img
                                                            src="images/avatars/avatar-4.png"
                                                            alt="user"
                                                            style={{ width: "8%" }}
                                                        />
                                                        <p className="pd-clint-name">swati menon</p>
                                                    </div>
                                                    <img src="images/5s.png" alt="star" style={{ width: "20%" }} />
                                                </div>
                                                <div>
                                                    <h4>Feedback</h4>
                                                    <p>
                                                        Hi I am JANOSE BERDEEN from chennai, Professional approach,
                                                        decent pricing , excellent quality of food with commitment on
                                                        the timings. Hassle-free Ordering experience. Hogist, the best
                                                        small party catering services in chennai ever experienced.
                                                    </p>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img
                                                            src="images/avatars/avatar-4.png"
                                                            alt="user"
                                                            style={{ width: "8%" }}
                                                        />
                                                        <p className="pd-clint-name">JANOSE BERDEEN I</p>
                                                    </div>
                                                    <img src="images/5s.png" alt="star" style={{ width: "20%" }} />
                                                </div>
                                                <div>
                                                    <h4>Feedback</h4>
                                                    <p>
                                                        When it comes to corporate catering in chennai . I would
                                                        recommend 100% Hogist. Their service and industrial catering app
                                                        would helps us getting good quality and food and save our time.
                                                    </p>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img
                                                            src="images/avatars/avatar-4.png"
                                                            alt="user"
                                                            style={{ width: "8%" }}
                                                        />
                                                        <p className="pd-clint-name">Mr. Sanzeeth</p>
                                                    </div>
                                                    <img src="images/4s.png" alt="star" style={{ width: "20%" }} />
                                                </div>
                                                <div>
                                                    <h4>Feedback</h4>
                                                    <p>
                                                        Food was excellent.... i will reach out to them even for the
                                                        upcoming events. The foods were clean, tasty and also was on
                                                        time with quality. Kudos to the Hogist team.speacial mention
                                                        Mrs.Renuka for the arrangements and Mr Alex and Satish for the
                                                        timely delivery.
                                                    </p>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img
                                                            src="images/avatars/avatar-4.png"
                                                            alt="user"
                                                            style={{ width: "8%" }}
                                                        />
                                                        <p className="pd-clint-name">yamunashankar krishnan</p>
                                                    </div>
                                                    <img src="images/5s.png" alt="star" style={{ width: "20%" }} />
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeInSection>
                    {/* mobile start*/}
                    <FadeInSection sectionId="section7">
                        <div className="mt-4">
                            <div className="container-fluid padding-ab-m3  pb-4" id="app">
                                <div className="row ">
                                    <div className="col-lg-6">
                                        <h2 className="m3-h2-text-style">
                                            Coming to you to make all your bulk food needs easier
                                        </h2>
                                    </div>
                                    <div className="col-lg-6 blog-view-btn">
                                        {/* <Link to="/"> */}{" "}
                                        <a
                                            style={{ color: "#fff" }}
                                            href="https://api.whatsapp.com/send?phone=+919962667733&text=Welcome HOGIST - The Catering service in Chennai. "
                                        >
                                            {" "}
                                            <button className="cnt-btn-style-main "> REACH US NOW</button>
                                        </a>
                                        {/* </Link> */}
                                    </div>
                                </div>
                                <div className="row m3-card-wdith m3-card-bottom">
                                    <div className="col-lg-4 mb-1">
                                        {/* <Link to="/blog/"> */}
                                        <div className="card-mobileapp-style txt-align-center">
                                            <img src="images/hogist-wlogo.png" alt="" style={{ width: "30%" }} />

                                            <h3 className="mt-4 mb-4 m3-blog-tit-styl">YOUR F&B</h3>
                                            <p>Revolutionized Industrial and Corporate Catering Services</p>
                                            <Link to="/industrial-catering-services-near-me/">
                                                {" "}
                                                <button className="mbap-btn-style-main mt-4"> KNOW MORE</button>
                                            </Link>
                                        </div>
                                        {/* </Link> */}
                                    </div>
                                    <div className="col-lg-4 mb-1">
                                        <div className="card-mobileapp-style txt-align-center">
                                            <img src="images/hogist-wlogo.png" alt="" style={{ width: "30%" }} />

                                            <h3 className="mt-4 mb-4 m3-blog-tit-styl">BULK FOOD ORDER</h3>
                                            <p>
                                                Experience the hassle-free bulk food ordering for all your events and
                                                ODC.
                                            </p>
                                            <Link to="/bulk-food-order/">
                                                {" "}
                                                <button className="mbap-btn-style-main mt-4 "> KNOW MORE</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-1">
                                        <div className="card-mobileapp-style txt-align-center">
                                            <img src="images/hogist-wlogo.png" alt="" style={{ width: "30%" }} />

                                            <h3 className="mt-4 mb-4 m3-blog-tit-styl">CAFE PARTNER</h3>
                                            <p>A Cafeteria with No Queues, Safe food, and Cashless Transaction.</p>
                                            <Link to="/corporate-catering-services-in-chennai/">
                                                {" "}
                                                <button className="mbap-btn-style-main mt-4 "> KNOW MORE</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeInSection>

                    {/* mobile end*/}
                </div>

                {/* Testimoniel Start */}
                {/* Vendor start */}
                <FadeInSection sectionId="section8">
                    <div
                        style={{
                            backgroundImage: "linear-gradient(227deg, rgb(50, 50, 50), rgb(0, 0, 0))",
                        }}
                    >
                        <div className="container pb-4">
                            <div className="faqs-titel-text-style">
                                <h2>Frequently Asked Questions</h2>
                                {/* <p></p> */}
                            </div>
                            <div>
                                <div className="itemcenter123 m3-font-700">
                                    <FAQS data={data} />
                                </div>
                            </div>
                            <div className="faqs-vendor-style txt-align-center pb-4">
                                <h3>Become a Vendor</h3>
                                <p className="m3-font-700">Grow your catering business with us.</p>
                                <Link to="/vendorregistration">
                                    {" "}
                                    <button className="cnt-btn-style-main123-vendor"> JOIN NOW</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </FadeInSection>
                {/* Vendor End */}
                {/* Blog start*/}
                <FadeInSection sectionId="section9">
                    <div>
                        <div className="container-fluid padding-ab-m3 ">
                            <p className="m3-spacer-small m3-font-600">Blogs</p>
                            <div className="row ">
                                <div className="col-lg-6">
                                    <h2 className="m3-h2-text-style">Let's check our latest blogs, articles.</h2>
                                </div>
                                <div className="col-lg-6 blog-view-btn">
                                    <Link to="/blog/">
                                        {" "}
                                        <button className="cnt-btn-style-main "> View More</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="row m3-card-wdith">
                                <div className="col-lg-4 m3-bl-bottom">
                                    <a href="https://www.hogist.com/blog/wedding-catering-services-in-chennai-2021">
                                        <div className="card-blog-style">
                                            <div className="card-blog-top-style">
                                                <img
                                                    src="images/blog.png"
                                                    alt=""
                                                    style={{ width: "100%", height: "100%" }}
                                                />
                                            </div>
                                            <div className="card-blog-buttom-style">
                                                <p className="mt-4">May 19,2023 | 5 Comment</p>
                                                <h3 className="mt-3 mb-2 m3-blog-tit-style">
                                                    7 Things to look for while hiring a wedding caterer in 2023
                                                </h3>
                                                <a href="https://www.hogist.com/blog/wedding-catering-services-in-chennai-2021">
                                                    {" "}
                                                    <button className="cnt-btn-style-main mt-4 ">
                                                        {" "}
                                                        Read More
                                                    </button>{" "}
                                                </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-4 m3-bl-bottom">
                                    <a href="https://www.hogist.com/blog/hire-best-corporate-caterer-in-chennai">
                                        <div className="card-blog-style">
                                            <div className="card-blog-top-style">
                                                <img
                                                    src="images/blog2.png"
                                                    alt=""
                                                    style={{ width: "100%", height: "100%" }}
                                                />
                                            </div>
                                            <div className="card-blog-buttom-style">
                                                <p className="mt-4">May 23,2023 | 6 Comment</p>
                                                <h3 className="mt-3 mb-2 m3-blog-tit-style">
                                                    How to Hire a best corporate caterer in Chennai?
                                                </h3>
                                                <a href="https://www.hogist.com/blog/hire-best-corporate-caterer-in-chennai">
                                                    {" "}
                                                    <button className="cnt-btn-style-main mt-4 "> Read More</button>
                                                </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-4 m3-bl-bottom">
                                    <a href="https://www.hogist.com/blog/traditional-vs-online-bulk-food-order">
                                        <div className="card-blog-style">
                                            <div className="card-blog-top-style">
                                                <img
                                                    src="images/blog3.png"
                                                    alt=""
                                                    style={{ width: "100%", height: "100%" }}
                                                />
                                            </div>
                                            <div className="card-blog-buttom-style">
                                                <p className="mt-4">June 10,2023 | 8 Comment</p>
                                                <h3 className="mt-3 mb-2 m3-blog-tit-style">
                                                    Traditional Vs Online Bulk Food Order - Comparison in 2022{" "}
                                                </h3>{" "}
                                                <a href="https://www.hogist.com/blog/traditional-vs-online-bulk-food-order">
                                                    {" "}
                                                    <button className="cnt-btn-style-main mt-4  ">
                                                        {" "}
                                                        Read More
                                                    </button>{" "}
                                                </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </FadeInSection>
                {/* Blog end*/}

                {/* App into */}

                {/* <div style={{ padding: "4% 0%" }}>
                    <div className="container">
                        <div className="app-bg-style">
                            <div className="row">
                                <div className="col-lg-6 itemcenter" style={{ textAlign: "center" }}>
                                    <div className="app-txt-top-style">
                                        <h2>Download our app to Get Exclusive Discounts!</h2>
                                        <div style={{ marginTop: "2%" }}>
                                            <img src="images/apple-store.png" alt="" style={{ width: "30%" }} />
                                            <a
                                                className="topbar-link"
                                                href="https://play.google.com/store/apps/details?id=com.hogist"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src="images/play-store.png" alt="" style={{ width: "30%" }} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-style-mt itemcenter">
                                    <img src="images/mobile12.png" alt="Hogist App" style={{ width: "67%" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div style={{ padding: "4% 0%" }}>
                    <div className="container ">
                        <div className="app-bg-style123">
                            <FadeInSection sectionId="section12">
                                <div className="row ">
                                    <div className="col-lg-6 itemcenter ">
                                        <div
                                            className="app-txt-top-style"
                                            style={{ textAlign: "center", width: "90%" }}
                                        >
                                            <h2>Download our app to Get Exclusive Discounts!</h2>
                                            <div style={{ marginTop: "2%" }}>
                                                {/* <img src="images/apple-store.png" alt="" className="app-playstore-width" /> */}
                                                <a
                                                    className="topbar-link"
                                                    href="https://play.google.com/store/apps/details?id=com.hogist"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img
                                                        src="images/play-store.png"
                                                        alt=""
                                                        className="app-playstore-width"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-style-mt itemcenter">
                                        <img src="images/mobile123.png" alt="Hogist App" style={{ width: "67%" }} />
                                    </div>
                                </div>
                            </FadeInSection>
                        </div>
                    </div>
                </div>
                {/* end */}

                <a href="https://wa.me/919962374733" className="float1234" target="_blank">
                    <WhatsAppIcon />
                </a>
            </div>
        </div>
    );
}

export default Mainhome3;
